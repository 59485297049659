import {
    Card,
    CardHeader,
    CardBody,
} from "reactstrap";
import React from 'react';
import QRCode from 'react-qr-code';
import "../../assets/css/qr-card-styles.css";
import Logo from '../../assets/img/brand/ebis-logo.png';

const StudentIdBack = ({ student }) => {

    return (
        <Card
            className="qRCardBack"
            style={{
                position: 'relative',
                margin: 'auto',
                color: 'white',
                borderRadius: '20px',
                textAlign: 'center',
                padding: '20px',
                overflow: 'hidden',
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '130px',
                    // backgroundColor: '#2F4FA2',
                    zIndex: 0,
                }}
                className="shape"
            ></div>
            <CardHeader
                className="idCardHeader"
                style={{ borderBottom: 'none', backgroundColor: 'transparent', position: 'relative', zIndex: 10 }}
            >
                <img src={Logo} alt="Institute Logo" width={60} crossOrigin="anonymous" />
            </CardHeader>
            <CardBody className="qrBackBody" style={{ zIndex: 1, position: 'relative' }}>
                <div className="qrDiv">
                    <QRCode value={student.barcode} size={100} className="code" />
                </div>
                <p className="termsTitle">Terms & Conditions</p>
                <p className="terms">This card is non-transferable and the property of Ebis Institute, Pannipitiya.
                    It is the cardholder's responsibility to protect and maintain the condition of his/her card.
                </p>
            </CardBody>
            <div className="instDetails">
                <p>Ebis Institute</p>
                <p>203/2A, Horana Road, Kottawa</p>
                <p>0718133770</p>
            </div>
        </Card>
    );
}
export default StudentIdBack;
