import React, { useEffect, useRef, useState } from 'react';
import {
    Card, CardHeader, Form, Row, Col, FormGroup, Input, Button, Label, Table,
    Dropdown, DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import '../../../assets/css/class-assistant-payments.css';
import StudentImage from "../../../assets/img/brand/blank_profile_pic.png";
import { getStudentByClassAssistants } from 'actions/ClassAssistantLayout';
import { findTeachersByGradeId } from 'actions/Teachers';
import { findAllSubjectsByTeacherIdAndGradeId } from 'actions/Subjects';
import { enrollStudent } from 'actions/Students';
import { getAllActiveGrades } from 'actions/Grades';
import Students from '../HighLevelAdmin/Students';

const ClassAssistantAssignStudent = () => {
    const [isData, setData] = useState({});
    const [isError, setError] = useState({});
    const [allClasses, setAllClasses] = useState(null);
    const barcodeInputRef = useRef(null);
    const [barcodeInput, setBarcodeInput] = useState('');

    const [gradeDropdownOpen, setGradeDropdownOpen] = useState(false);
    const [selectedGradeForStudent, setSelectedGradeForStudent] = useState(null);
    const [allTeachersByGrade, setAllTeachersByGrade] = useState(null);
    const [teacherDropdownOpen, setTeacherDropdownOpen] = useState(false);
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [allSubjects, setAllSubjects] = useState(null);
    const [subjectDropdownOpen, setSubjectDropdownOpen] = useState(false);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [allGrades, setAllGrades] = useState(null);

    const [isInsertButtonDisabled, setinsertButtonDisabled] = useState(true);
    const [isSuccess, setSuccess] = useState(false);
    const [isSuccessMessage, setSuccessMessage] = useState("");
    const [isFailed, setFailed] = useState(false);
    const [isErrorMessage, setErrorMessage] = useState("");
    const [selectedClasses, setSelectedClasses] = useState([]);

    const [enrolledData, setEnrolledData] = useState({
        id: "",
        enrolled_classes: [],
    });

    const fetchData = async () => {
        try {
          const grades = await getAllActiveGrades();
          setAllGrades(grades);
        } catch (error) {
        }
      };

      useEffect(() => {
        fetchData();
      }, []);
    
    const handleInputChange = (event) => {
        setError({ ...isError, [event.target.name]: "" });
        if (event.target.name === 'admission_fee') {
            setData({ ...isData, [event.target.name]: Number(event.target.value) });
        } else {
            setData({ ...isData, [event.target.name]: event.target.value });
        }
    };

    const getStudentEnrolledData = async (barcode) => {
        const response = await getStudentByClassAssistants(barcode);
                if (response.success === true) {
                    setData(response.students);
                    setEnrolledData({ ...enrolledData, id: response.students.id });
                    setAllClasses(response.students.class_users);
                } if (response.success === false) {
                    setData({ barcode: barcode });
                    setAllClasses(null);
                }
    }

    const getStudent = async (e) => {
        let barcode = e.target.value;
        if (barcode.length <= 7) {
            setBarcodeInput(e.target.value);
        } else
            if (barcode.length > 7) {
                barcode = barcode.slice(7);
                setBarcodeInput(barcode);
            }
        handleInputChange(e);
        try {
            if (barcode.length === 7) {
                await getStudentEnrolledData(barcode);
            }
        } catch (error) {
            setData({});
            setAllClasses(null);
        }
    };

    const getTeachers = async (gradeId) => {
        const teachersByGrade = await findTeachersByGradeId(gradeId);
        setAllTeachersByGrade(teachersByGrade);
    }

    const getSubjects = async (gradeId, teacherId) => {
        const subjectsByGradeAndTeacher = await findAllSubjectsByTeacherIdAndGradeId(gradeId, teacherId);
        setAllSubjects(subjectsByGradeAndTeacher);
    }

    const addClass = async () => {
        const newEnrolledClass = {
            teacherId: selectedTeacher.id,
            gradeId: selectedGradeForStudent.id,
            subjectId: selectedSubject.id
        };

        setEnrolledData(prevState => {
            const isAlreadyEnrolled = prevState.enrolled_classes.some(
                enrolledClass =>
                    enrolledClass.teacherId === newEnrolledClass.teacherId &&
                    enrolledClass.gradeId === newEnrolledClass.gradeId &&
                    enrolledClass.subjectId === newEnrolledClass.subjectId
            );

            if (isAlreadyEnrolled) {
                setFailed(true);
                setErrorMessage('This student is already enrolled in this class.');
                return prevState; 
            }

            const updatedEnrolledClasses = [...prevState.enrolled_classes, newEnrolledClass];
            return {
                ...prevState,
                enrolled_classes: updatedEnrolledClasses
            };
        });

        setSelectedClasses(prevSelectedClasses => {
            const isAlreadySelected = prevSelectedClasses.some(
                selectedClass =>
                    selectedClass.grade === selectedGradeForStudent.name &&
                    selectedClass.teacher === selectedTeacher.full_name &&
                    selectedClass.subject === selectedSubject.name
            );
            if (!isAlreadySelected) {
                return [
                    ...prevSelectedClasses,
                    { grade: selectedGradeForStudent.name, teacher: selectedTeacher.full_name, subject: selectedSubject.name }
                ];
            }
            return prevSelectedClasses; 
        });
    };

    const insertNewEnrollment = async () => {
        setinsertButtonDisabled(true);
        const response = await enrollStudent(enrolledData);
        if (response.success === true) {
          getStudentEnrolledData(barcodeInput);
          setSuccessMessage("Succesfully Enrolled the Student!");
          setSuccess(true);
          setSelectedGradeForStudent(null);
          setSelectedTeacher(null);
          setSelectedSubject(null);
          setSelectedClasses([]);
        } else {
          setErrorMessage(response.message);
          setFailed(true);
        }
      };

    const removeSelectedClass = (indexToRemove) => {
        setSelectedClasses(prevSelectedClasses => {
          return prevSelectedClasses.filter((_, index) => index !== indexToRemove);
        });
      };

    const removeEnrolledClass = (enrolledClassIdToRemove) => {
        setEnrolledData(prevState => {
          const updatedEnrolledClasses = prevState.enrolled_classes.slice(); 
          updatedEnrolledClasses.splice(enrolledClassIdToRemove, 1);
          return {
            ...prevState,
            enrolled_classes: updatedEnrolledClasses
          };
        });
        removeSelectedClass(enrolledClassIdToRemove);
    
      };
    return (
        <>
            <div className="mt--3 container-fluid assistant-container">
                <Card className="mt-4 mb-4 pb-4 shadow">
                    <CardHeader className="border-0 assistantStudentHeader">
                        <h3 className="mb-0">Student Classes</h3>
                    </CardHeader>
                    <div className='classList'>
                        <div className='bodySection' >
                            <div className='ml-4 searchSection'>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Input
                                                id="barcode"
                                                placeholder="Barcode"
                                                type="text"
                                                value={barcodeInput}
                                                onChange={getStudent}
                                                ref={barcodeInputRef}
                                                name="barcode"
                                                className='barcodeSearchInput'
                                                autoFocus
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Input
                                                id="student_id"
                                                placeholder="Student Id"
                                                type="text"
                                                value={isData?.registration_number}
                                                name="student_id"
                                                disabled
                                                className='barcodeSearchInput'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <Button onClick={''} color="primary">Reset</Button>
                                    </Col>
                                </Row>
                            </div>
                            <div className='classPaymentOptions'>
                                <Col sm="12" md="12" lg="12">
                                    {allClasses?.length > 0 ?
                                        <Table className="align-items-center" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">Teacher</th>
                                                    <th scope="col">Subject</th>
                                                    <th scope="col">Grade</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allClasses?.map((classUser, index) => (
                                                    <tr key={index}>
                                                        <td>{classUser.teacher_class.teacher.full_name}</td>
                                                        <td>{classUser.teacher_class.subject.name}</td>
                                                        <td>{classUser.teacher_class.grade.name}</td>
                                                    </tr>))}
                                            </tbody>
                                        </Table> : null}
                                </Col>
                            </div>
                        </div>
                        {allClasses !== null ? (
                           <div className="studentSection">
                           <div className="profile-card">
                             <div className="imageStudent">
                               <img src={isData.image_url ? isData.image_url : StudentImage} className="studentPaymentImage" />
                             </div>
                             <div className="studentDetailsClassAssistant">
                               <h3><i class="fa-solid fa-circle-user"></i> {isData.full_name}</h3>
                               <p style={{ fontSize: "12px" }}><i class="fa-solid fa-registered"></i> {isData.registration_number}</p>
                               {isData.phone_number !== null ? <p><i class="fa-solid fa-phone-volume"></i> {isData.phone_number}</p> : null}
                             </div>
                           </div>
                         </div>
                        ) : null}
                    </div>
                    
                    <Form className="ml-4 mb-4 mr-4">
                                <Row style={{ justifyContent: "space-between" }}>
                                    <Col md="12">  
                                    <hr/>                   
                                    <h3 className='mt-3 mb-3'>Add New Class</h3>
                                        <Row style={{ justifyContent: "space-between" }}>
                                            <Col md="12" lg="2">
                                                <FormGroup>
                                                    <Dropdown
                                                        isOpen={gradeDropdownOpen}
                                                        toggle={() =>
                                                            setGradeDropdownOpen(!gradeDropdownOpen)
                                                        }
                                                    >
                                                        <DropdownToggle caret>
                                                            {selectedGradeForStudent ? selectedGradeForStudent.name : "Grade"}
                                                        </DropdownToggle>
                                                        {allGrades && allGrades.length > 0 ? (
                                                            <DropdownMenu className="dropdownMenuStyle">
                                                                {allGrades && allGrades.map((grade) => (
                                                                    <DropdownItem
                                                                        key={grade.name}
                                                                        value={grade.id}
                                                                        onClick={() => [
                                                                            setSelectedTeacher(null),
                                                                            setSelectedSubject(null),
                                                                            setSelectedGradeForStudent(grade),
                                                                            getTeachers(grade.id)
                                                                        ]}
                                                                    >
                                                                        {grade.name}
                                                                    </DropdownItem>
                                                                ))}
                                                            </DropdownMenu>
                                                        ) : null}
                                                    </Dropdown>
                                                </FormGroup>
                                            </Col>
                                            <Col md="12" lg="4">
                                                <FormGroup>
                                                    <Dropdown
                                                        isOpen={teacherDropdownOpen}
                                                        toggle={() =>
                                                            setTeacherDropdownOpen(!teacherDropdownOpen)
                                                        }
                                                    >
                                                        <DropdownToggle caret>
                                                            {selectedTeacher ? selectedTeacher.full_name : "Teacher"}
                                                        </DropdownToggle>
                                                        {allTeachersByGrade && allTeachersByGrade.length > 0 ? (
                                                            <DropdownMenu>
                                                                {allTeachersByGrade && allTeachersByGrade.map((teacher) => (
                                                                    <DropdownItem
                                                                        key={teacher.full_name}
                                                                        value={teacher.id}
                                                                        onClick={() => {
                                                                            setSelectedTeacher(teacher);
                                                                            setSelectedSubject(null);
                                                                            getSubjects(selectedGradeForStudent.id, teacher.id);
                                                                            setTeacherDropdownOpen(false);
                                                                        }}
                                                                    >
                                                                        {teacher.full_name}
                                                                    </DropdownItem>
                                                                ))}
                                                            </DropdownMenu>
                                                        ) : null}
                                                    </Dropdown>
                                                </FormGroup>
                                            </Col>
                                            <Col md="12" lg="4">
                                                <FormGroup>
                                                    <Dropdown
                                                        isOpen={subjectDropdownOpen}
                                                        toggle={() =>
                                                            setSubjectDropdownOpen(!subjectDropdownOpen)
                                                        }
                                                    >
                                                        <DropdownToggle caret>
                                                            {selectedSubject ? selectedSubject.name : "Subject"}
                                                        </DropdownToggle>
                                                        {allSubjects && allSubjects.length > 0 ? (
                                                            <DropdownMenu>
                                                                {allSubjects && allSubjects.map((subject) => (
                                                                    <DropdownItem
                                                                        key={subject.name}
                                                                        value={subject.id}
                                                                        onClick={() => {
                                                                            setSelectedSubject(subject);
                                                                            setSubjectDropdownOpen(false);
                                                                            setinsertButtonDisabled(false);
                                                                        }}
                                                                    >
                                                                        {subject.name}
                                                                    </DropdownItem>
                                                                ))}
                                                            </DropdownMenu>
                                                        ) : null}
                                                    </Dropdown>
                                                </FormGroup>
                                            </Col>
                                            <Col md="12" lg="2">
                                                <FormGroup>
                                                    <Button color="primary" style={{ height: "100%" }} onClick={() => addClass()}>✓</Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Table className="align-items-center" responsive>
                                                <tbody>
                                                    {selectedClasses?.map((selectedClass, index) => (
                                                        <tr key={index}>
                                                            <td>{selectedClass.teacher}</td>
                                                            <td>{selectedClass.subject} - {selectedClass.grade}  </td>
                                                            <td><span style={{ cursor: "pointer", color: "red" }} onClick={() => removeEnrolledClass(index)}>X</span></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingLeft: "15px" }}>
                                    <Button
                                        color="primary"
                                        type="button"
                                        onClick={() => {
                                            insertNewEnrollment();
                                        }}
                                        disabled={
                                            isInsertButtonDisabled
                                        }
                                    >
                                        Insert
                                    </Button>
                                </Row>
                            </Form>
                </Card>
            </div>
        </>
    )
}

export default ClassAssistantAssignStudent;