import {
    Card,
    CardHeader,
    Row,
    Col,
    CardBody,
    CardFooter,
} from "reactstrap";
import React, { useEffect, useState } from 'react';
import "../../assets/css/qr-card-styles.css";
import Barcode from "react-barcode";
import Logo from '../../assets/img/brand/ebis-logo.png';
import BlankImage from '../../assets/img/brand/blank_profile_pic.png';


const StudentIdCard = ({ student }) => {
    return (
        <Card
            className="qRCard"
            style={{
                position: 'relative',
                margin: 'auto',
                color: 'white',
                borderRadius: '20px',
                textAlign: 'center',
                padding: '20px',
                overflow: 'hidden',
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '130px',
                    // backgroundColor: '#2F4FA2',
                    zIndex: 0,
                }}
                className="shape"
            ></div>

            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: '10px',
                    backgroundColor: '#2F4FA2',
                    zIndex: 0,
                }}
            ></div>
            <CardHeader
                className="idCardHeader"
                style={{ borderBottom: 'none', backgroundColor: 'transparent', position: 'relative', zIndex: 10 }}
            >
                <img src={Logo} alt="Institute Logo" width={60} crossOrigin="anonymous" />
                <p className="instName">
                    ebis Higher Educational Institute <br />
                    Kottawa
                </p>
            </CardHeader>
            <div className={styles.qrBody} style={{ zIndex: 1, position: 'relative' }}>
                <div style={styles.studentInfo}>
                    <img src={
                        student.image_url
                            ? student.image_url
                            : BlankImage
                    } style={styles.photoPlaceholder} crossOrigin="Access-Control-Allow-Origin" />                    
                    <h6 style={{ marginBottom: '2px', marginTop: '2px' }}>{student.registration_number}</h6>
                    <h5 style={{ marginBottom: '0px' }}>{student.full_name}</h5>
                </div>
            </div>
            <Barcode
                className="barCodeId"
                value={student.barcode}
                height={40}
                fontSize={12}
                style={{ position: 'relative', zIndex: 1 }}
            />
        </Card>

    );
};

const styles = {
    header: {
      textAlign: 'center',
      marginBottom: '5px',
    },
    logo: {
      width: '40px',
      height: '50px',
    },
    instituteName: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    instituteDetails: {
      fontSize: '12px',
      color: '#555',
    },
    studentInfo: {
      textAlign: 'center',
    },
    photoPlaceholder: {
      width: '86px',
      height: '102px',
      borderRadius: '15px',
      backgroundColor: '#d9d9d9',
      margin: '0 auto',
    },
    barcode: {
      display: 'block',
      margin: '10px auto',
    },
    contactInfoSec: {
      textAlign: 'center',
      fontSize: '10px',
      color: '#333',
    },
    contactInfo: {
      textAlign: 'center',
      fontSize: '8px',
      color: '#333',
      lineHeight: '1.4'
    },
    qrBody: {
      padding:'0px'
    }
  };

export default StudentIdCard;
