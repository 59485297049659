import AttendanceSheet from "views/pages/AttendanceSheet";
import CAAttendanceSheet from "views/pages/ClassAssistant/CAAttendanceSheet";
import CAClassTeacherPaymentRecords from "views/pages/ClassAssistant/CAClassTeacherPaymentRecords";
import CATeacherPaymentDetails from "views/pages/ClassAssistant/CATeacherPaymentDetails";
import ClassAssistantAssignStudent from "views/pages/ClassAssistant/ClassAssistantAssignStudent";
import ClassAssistantReport from "views/pages/ClassAssistant/ClassAssistantReport";
import ClassAssistantStudentPayments from "views/pages/ClassAssistant/ClassAssistantStudentPayments";
import ClassAssistantStudentPayType from "views/pages/ClassAssistant/ClassAssistantStudentPayType";
import ClassAssistantTeacherDetails from "views/pages/ClassAssistant/ClassAssistantTeacherDetails";
import ClassAssistantTeacherPayments from "views/pages/ClassAssistant/ClassAssistantTeacherPayments";
import ClassAssistantTeachers from "views/pages/ClassAssistant/ClassAssistantTeachers";
import ClassAssistantStudent from "views/pages/ClassAssistant/ClassAssistnatStudent";
import Recepts from "views/pages/ClassAssistant/Recepts";
import ClassStudentList from "views/pages/ClassStudentList";
import ClassTeacherDailyPaymentRecords from "views/pages/ClassTeacherDailyPaymentRecords";
import ClassTeacherPaymentRecords from "views/pages/ClassTeacherPaymentRecords";

var classAssistantRoutes = [
    {
        path: "/student",
        name: "Student",
        icon: "ni fa fa-users text-ebis",
        component: <ClassAssistantStudent />,
        layout: "/classassistant",
    },
    {
        path: "/payments",
        name: "Payments",
        icon: "ni fa-regular fa-credit-card text-ebis",
        component: <ClassAssistantStudentPayments />,
        layout: "/classassistant",
    },
    {
        path: "/teachers",
        name: "Teachers",
        icon: "ni fa fa-graduation-cap text-ebis",
        component: <ClassAssistantTeachers />,
        layout: "/classassistant",
    },
    {
        path: "/teacher-payments",
        name: "Receipts",
        icon: "ni fa-solid fa-file-invoice text-ebis",
        component: <ClassAssistantTeacherPayments />,
        layout: "/classassistant",
    },
    {
        path: "/student-fee",
        name: "Free Card",
        icon: "ni fa fa-money-bill text-ebis",
        component: <ClassAssistantStudentPayType />,
        layout: "/classassistant",
    },
    {
        path: "/student-assign",
        name: "Assign",
        icon: "ni fa fa-user text-ebis",
        component: <ClassAssistantAssignStudent />,
        layout: "/classassistant",
    },
    {
        path: "/teachers/:id",
        name: "Teacher Class Details",
        icon: "ni ni-bus-front-12 text-orange",
        component: <ClassAssistantTeacherDetails />,
        layout: "/classassistant",
    },
    {
        path: "/teachers/payments/:teacherId/:full_name",
        name: "Class Teacher Payment Records",
        icon: "ni ni-bus-front-12 text-orange",
        component: <CATeacherPaymentDetails />,
        layout: "/classassistant",
    },
    {
        path: "/teachers/classStudents/:classTeacherId",
        name: "Class Teacher Students",
        icon: "ni ni-bus-front-12 text-orange",
        component: <ClassStudentList />,
        layout: "/classassistant",
      },
      {
          path: "/teachers/payment-records/:teacherClassId/:month",
          name: "Class Teacher Payment Records",
          icon: "ni ni-bus-front-12 text-orange",
          component: <CAClassTeacherPaymentRecords />,
          layout: "/classassistant",
        },
        {
          path: "/teachers/daily-payment-records/:teacherClassId/:date",
          name: "Class Teacher Daily Payment Records",
          icon: "ni ni-bus-front-12 text-orange",
          component: <ClassTeacherDailyPaymentRecords />,
          layout: "/classassistant",
        },
        {
          path: "/teachers/attendance-records/:teacherClassId/:date",
          name: "Class Teacher Payment Records",
          icon: "ni ni-bus-front-12 text-orange",
          component: <CAAttendanceSheet/>,
          layout: "/classassistant",
        },
    // {
    //     path: "/institute-report",
    //     name: "Report",
    //     icon: "ni fa fa-user text-ebis",
    //     component: <ClassAssistantReport />,
    //     layout: "/classassistant",
    // },
    // {
    //     path: "/receipts",
    //     name: "Print",
    //     icon: "ni fa fa-user text-ebis",
    //     component: <Recepts />,
    //     layout: "/classassistant",
    // },
];

export default classAssistantRoutes;